import "core-js/modules/es.array.push.js";
import { defineComponent, reactive, toRefs, computed, watch, nextTick } from 'vue';
import Card from '@/components/Card';
import { ElUpload } from "element-plus";
import httpApi from "@/api/httpApi";
import axios from 'axios';
import { useMessage } from "@/hooks/web/useMessage";
import API from "@/api/authority/index";
import { useStore } from "vuex";
import emitter from "@/eventBus/index";
import { useDebounce } from '@/hooks/core/useDebounce';
export default defineComponent({
  name: 'LogoSettings',
  components: {
    Card,
    ElUpload
  },
  setup() {
    const {
      state
    } = useStore();
    const message = useMessage();
    const refData = reactive({
      platformName: '',
      uploadImage: null,
      imgFile: null,
      homeBanner: []
    });
    const appTitle = computed(() => state.appSettings.appTitle);
    const logoImg = computed(() => state.appSettings.logoImg);
    const homeIconList = computed(() => state.appSettings.homeIconList);
    watch(() => appTitle.value, val => {
      refData.platformName = val;
    });
    watch(() => logoImg.value, val => {
      refData.uploadImage = val;
    });
    watch(() => homeIconList.value, val => {
      refData.homeBanner = val;
    });
    nextTick(() => {
      refData.platformName = appTitle.value;
      refData.uploadImage = logoImg.value;
      refData.homeBanner = homeIconList.value || [];
    });
    //上传图片
    const handleRequest = async (file, isMultiple) => {
      // 获取oss数据
      try {
        if (!['image/png', 'image/jpeg'].includes(file.raw.type)) throw '只能上传 jpg/png 文件，且不超过 5M';
        if (file.raw.size / 1024 > 1024 * 5) throw '只能上传 jpg/png 文件，且不超过 5M';
        const {
          code,
          msg,
          data
        } = await httpApi.getWebPolicy({});
        if (code !== 1) throw msg;
        let fileObj = file.raw;
        let imgName = file.name + '_' + new Date().valueOf() + '.jpg';
        // let imgName = file.name + '.jpg';
        let ossData = new FormData();
        // 添加配置参数
        ossData.append('OSSAccessKeyId', data.accessId);
        ossData.append('policy', data.policy);
        ossData.append('Signature', data.signature);
        ossData.append("success_action_status", '200');
        ossData.append("key", data.dir + imgName);
        ossData.append("file", fileObj, imgName);
        axios({
          url: data.host,
          data: ossData,
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if (res.status === 200) {
            if (isMultiple) {
              refData.homeBanner.push(data.host + '/' + data.dir + imgName);
            } else {
              refData.uploadImage = data.host + '/' + data.dir + imgName;
            }
          }
        }).catch(res => {
          console.log('错误', res);
        });
      } catch (error) {
        message.error(error);
      }
    };
    //删除首页展览图
    const handleRemove = delIndex => {
      refData.homeBanner = refData.homeBanner.filter((item, index) => index != delIndex);
    };
    const [debounceRemove] = useDebounce(handleRemove, 200);
    //保存按钮
    const handleSave = async () => {
      if (refData.platformName.length > 20) {
        message.warning('平台名称设置在20个字以内');
        return false;
      }
      const params = {
        id: state.appSettings.id,
        tenantId: state.appSettings.tenantId,
        deptId: state.appSettings.deptId,
        platformName: refData.platformName,
        iconUrl: refData.uploadImage,
        homeIconList: JSON.stringify(refData.homeBanner)
      };
      const {
        code,
        msg
      } = await API.editLoginConf(params);
      message[code == 0 ? 'success' : 'warning'](code == 0 ? '保存成功' : msg);
      code == 0 && emitter.emit('emitWebInfo');
    };
    return {
      ...toRefs(refData),
      handleRequest,
      handleSave,
      debounceRemove
    };
  }
});